<script>
export default {}
</script>

<template>
  <v-card>
    <v-card-text>
      <v-alert type="error" :value="true">
        <h3>The page you are looking for cannot be found</h3>
      </v-alert>

      <div class="text-center">Please go to the home page</div>

      <div class="pt-3"></div>

      <div>
        <v-btn block outlined to="/">Go home</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>
